import axios from 'axios';

let conversationStep = 0;

const conversationData = {
    get step() {
        return conversationStep;
    },
    set step(value) {
        conversationStep = value;
    },
    interazione: []
}

// export const addToConversation = (userMessage, botMessage) => {

//     conversationData.interazione.push(
//         { sender: "maria", message: userMessage },
//         { sender: "bot", message: botMessage }
//     );

//     sendToEndpoint(conversationData);

//     conversationData.step = ++conversationStep;
// };

export const addQuestionToConversation = (userMessage, endpointEnabled) => {
    conversationData.interazione.push({ sender: "maria", message: userMessage });

    if (endpointEnabled) {
        sendToEndpoint(conversationData);
    }    

    // conversationData.step = ++conversationStep
};

export const addResponseToConversation = (botMessage, endpointEnabled) => {
    conversationData.interazione.push({ sender: "bot", message: botMessage });

    if (endpointEnabled) {
        sendToEndpoint(conversationData);
    }    

    // conversationData.step = ++conversationStep
};

export const resetEndpoint = (endpointEnabled) => {

    console.log("Endpoint Enabled:", endpointEnabled);

    if (endpointEnabled) {
        conversationData.step = -1;
        conversationData.interazione = [];
        sendToEndpoint(conversationData);

        // conversationData.step = ++conversationStep;
    }

};

export const resetConversation = (tester, endpointEnabled) => {

    if (endpointEnabled) {
        conversationData.interazione = [];
        sendToEndpoint(conversationData);
        // conversationData.step = ++conversationStep;
    }    
}

const sendToEndpoint = async (data) => {
    console.log("Invio dati all'endpoint:", JSON.stringify(data, null, 2));

    // const endpointUrl = "https://genai4financialadvisor.azurewebsites.net/data";

    const endpointUrl = "https://genai4financialadvisor-immersive.azurewebsites.net/data";

    try {
        const response = await axios.post(endpointUrl, data, {
            headers: {
                'Content-Type': 'application/json'
            }
        });

        console.log("Risposta del server:", response.data);
    } catch (err) {
        console.log("Errore nell'invio dei dati:", err);
    }
}

export const previousStep = async () => {

    // const endpointUrl = "https://genai4financialadvisor.azurewebsites.net/prev";
    // https://genai4financialadvisor-immersive.azurewebsites.net/

    const endpointUrl = "https://genai4financialadvisor-immersive.azurewebsites.net/prev";
    
    try {
        const response = await axios.get(endpointUrl, {
            headers: {
                'Content-Type': 'application/json'
            }
        });    
        console.log("Risposta:", response.data )
        conversationData.step = response.data.step;
    } catch (error) {
        console.log("Errore nella comunicazione con l'endpoint:", error)
    }
}

export const nextStep = async () => {

    console.log("Invio nuovo step all'endpoint");
    // const endpointUrl = "https://genai4financialadvisor.azurewebsites.net/next";

    const endpointUrl = "https://genai4financialadvisor-immersive.azurewebsites.net/next";

    try {
        const response = await axios.get(endpointUrl, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        console.log("Risposta:", response.data );        
        conversationData.step = response.data.step;
    } catch (error) {
        console.log("Errore nella comunicazione con l'endpoint:", error)
    }
}